<template>
    <div >
        
        <van-nav-bar
            title="客户详情"
            left-arrow
            @click-left="onClickLeft"
        />
        <div class="imgDiv">
            <img src="../../assets/image/powerMeasure/sumit_success.png" alt="">
            <p class="statusApply">申请已提交</p>
            <p class="chatDiv">如有任何问题,可联系您的</p>
            <p class="chatDetail">业务员:{{name}}; 联系电话:{{phone}}</p>
            <div v-show="(this.plan == 1)">
                <p class="htdiv">您可同时<a class="htMB" href="/static/contract.pdf" download="浙江售电公司与电力用户购售电合同（2023年版）.pdf">下载合同模版</a>,了解合同内容</p>
            </div>
            <div v-show="(this.plan == 2)" class="planDiv">
                <p>您可同时联系您的</p>
                <p class="phoneText">专属顾问:0571-85353089</p>
            </div>
            
        </div>
       
        <div class="toLook" @click="toLogin">查看详情</div>
      
       
     
      
        
        
    </div>
</template>
<script>
export default{
        name:'CustomerSumit',
        data(){
            return{
              name:  sessionStorage.getItem('ywyName'),
               phone: sessionStorage.getItem('ywyPhone'),
                plan:'' // 1 折扣方案 2 储能方案
            }
        },
        methods:{
            onClickLeft(){
                this.$router.back();
            }, 
            toLogin(){
                this.$router.replace('/login');
            }
        },
       
    
        mounted(){
           this.plan= this.$route.query.plan
           
        },
        updated() {
            
        },
        created(){
           
        }
        
       
    }
</script>
<style scoped>
.imgDiv{
    display: flex;
    justify-content: center;
    margin-top: 100px;
    flex-direction: column;
    align-items: center;
}
.imgDiv img{
    width: 309px;
}
.statusApply{
    font-size: 48px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #343434;
    line-height: 69px;
    margin-top: 40px;
}
.chatDiv{
   
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #818181;
    line-height: 70px;
}
.chatDetail{
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #818181;
    line-height: 28px;
}
.htdiv{
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #343434;
    line-height: 50px;
    margin-top: 60px;
}
.htMB{
    margin-left: 10px;
    margin-right: 10px;
    padding: 5px 15px;
    background: #06B2B2;
    border-radius: 10px;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 54px;
}
.toLook{
    width: 88%;
    line-height: 80px;
    background: #06B2B2;
    border-radius: 40px;
   text-align: center;
    font-size: 34px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    margin: 100px auto;
}
.phoneText{
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #01A1A1;
    line-height: 50px;

}
.planDiv{
    text-align: center;
    margin-top: 70px;

}
</style>